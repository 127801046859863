<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <!-- <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div> -->

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- 포인트 설정 검토중이므로 숨김 처리 -->
      <!-- <b-button variant="primary" class="mr-1" @click="updatePointSettings" :hidden="userData.role != 'admin'">
         <span class="text-nowrap">포인트 설정</span>
      </b-button> -->
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ userData.name }}({{ userData.nickName }})
            </p>
            <span class="user-status">{{ userData.role == "admin" ? "관리자" :  userData.role == "typist" ? "타이피스트" : "" }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="userData.avatar"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>
        <b-dropdown-item link-class="d-flex align-items-center" v-b-modal.password-change-modal>
          <feather-icon
            size="16"
            icon="LockIcon"
            class="mr-50"
          />
          <span>비밀번호 변경</span>
        </b-dropdown-item>
        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>로그아웃</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
    <PasswordChangeModal />
  </div>
</template>

<script>
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'
import PasswordChangeModal from './PasswordChangeModal'
export default {
  components: {
    // Navbar Components
    DarkToggler,
    PasswordChangeModal
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  methods: {
    /**
     * 포인트 설정 페이지 이동               
     */
    updatePointSettings(){
        this.$router.push({path : '/admin/point_settings/update'}).catch(()=>{});
    },
    logout(){
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem('userData')
      this.$ability.update(initialAbility)
      this.$router.push({ name: 'login' })
    }
  },
}
</script>
