<template>
    <div>
        <b-modal
            id="password-change-modal"
            hide-header
            scrollable
            hide-footer
            centered
            >

            <div class="d-flex mt-1">
                <feather-icon
                    icon="DatabaseIcon"
                    size=24
                />
                <h4 class="ml-50 mb-2">비밀번호 변경</h4>
            </div>
            <b-row class="mt-1 mb-1">
                <b-col>
                    <b-form-group label="아이디(email)">
                        <b-input placeholder="아이디(email)" v-model="email" />
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row class="mt-1 mb-1">
                <b-col>
                    <b-form-group label="현재 비밀번호">
                        <b-input-group
                            class="input-group-merge"
                        >
                            <b-form-input
                            id="password"
                            v-model="password"
                            :type="passwordFieldType"
                            class="form-control-merge"
                            placeholder="현재 비밀번호"
                            />

                            <b-input-group-append is-text>
                            <feather-icon
                                class="cursor-pointer"
                                :icon="passwordToggleIcon"
                                @click="togglePasswordVisibility"
                            />
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            
            <b-row class="mt-1 mb-1">
                <b-col>
                    <b-form-group label="새 비밀번호">
                        <b-input-group
                            class="input-group-merge"
                        >
                            <b-form-input
                            id="newPassword"
                            v-model="newPassword"
                            :type="newPasswordFieldType"
                            class="form-control-merge"
                            placeholder="새 비밀번호"
                            />

                            <b-input-group-append is-text>
                            <feather-icon
                                class="cursor-pointer"
                                :icon="newPasswordToggleIcon"
                                @click="toggleNewPasswordVisibility"
                            />
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="mt-1 mb-1">
                <b-col>
                    <b-form-group label="새 비밀번호확인">
                        <b-input-group
                            class="input-group-merge"
                        >
                            <b-form-input
                            id="password"
                            v-model="newPasswordConfirm"
                            :type="newPasswordConfirmFieldType"
                            class="form-control-merge"
                            placeholder="새 비밀번호확인"
                            />

                            <b-input-group-append is-text>
                            <feather-icon
                                class="cursor-pointer"
                                :icon="newPasswordConfirmToggleIcon"
                                @click="toggleNewPasswordConfirmVisibility"
                            />
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>


            <b-row class="d-flex justify-content-center mt-4 mb-1">
                <b-button variant="primary" size="md" @click="changePassword">
                    비밀번호 변경
                </b-button>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import axios from 'axios';
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Constant from '@/Constant';
import useJwt from '@/auth/jwt/useJwt'

let BASEURL = Constant.BASEURL;

export default {
    name : 'PasswordChangeModal',
    mixins: [togglePasswordVisibility],
    components : {
        ToastificationContent,
    },
    data() {
        return {
            email : '',
            password : '',
            newPassword : '',
            newPasswordConfirm : '',
            newPasswordFieldType : 'password',
            newPasswordConfirmFieldType : 'password',
        }
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        newPasswordToggleIcon() {
            return this.newPasswordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
        },
        newPasswordConfirmToggleIcon() {
            return this.newPasswordConfirmFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
    },
    methods : {
        changePassword() {
            const headers =  {
                'X-AUTH-TOKEN': localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
            }
            let param = {
                email : this.email,
                oldPassword : this.password,
                newPassword : this.newPassword
            };
            axios.post(`${BASEURL}/v1/user/changePassword`,null,{
                params : param,
                headers
            })
            .then(({data})=>{
                if(data.success) {
                    this.$toast({
                        component :ToastificationContent,
                        props: {
                            title: '알림',
                            icon: 'BellIcon',
                            text: '변경되었습니다',
                        }
                    })
                    this.$bvModal.hide('password-change-modal');
                }
            })
            .catch((err)=> {
                console.log("에러 : ", err);
            })
        },
        toggleNewPasswordVisibility(){
            this.newPasswordFieldType = this.newPasswordFieldType === 'password' ? 'text' : 'password';
        },
        toggleNewPasswordConfirmVisibility(){
            this.newPasswordConfirmFieldType = this.newPasswordConfirmFieldType === 'password' ? 'text' : 'password';
        },
    }
}
</script>
<style>
</style>